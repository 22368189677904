/* Webfont: Slenco-Black */
@font-face {
  font-family: "SlencoBlack";
  src: url("../assets/fonts/Slenco-Black.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/Slenco-Black.woff2")
      format("woff2"),
    /* Modern Browsers */ url("../assets/fonts/Slenco-Black.ttf")
      format("truetype"); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Slenco-BlackItalic */
@font-face {
  font-family: "SlencoBlackIta";
  src: url("../assets/fonts/Slenco-BlackItalic.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/Slenco-BlackItalic.woff2")
      format("woff2"),
    /* Modern Browsers */ url("../assets/fonts/Slenco-BlackItalic.ttf")
      format("truetype"); /* Safari, Android, iOS */
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Slenco-Bold */
@font-face {
  font-family: "SlencoBold";
  src: url("../assets/fonts/Slenco-Bold.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/Slenco-Bold.woff2")
      format("woff2"),
    /* Modern Browsers */ url("../assets/fonts/Slenco-Bold.ttf")
      format("truetype"); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: bolder;
  text-rendering: optimizeLegibility;
}

/* Webfont: Slenco-BoldItalic */
@font-face {
  font-family: "SlencoIta";
  src: url("../assets/fonts/Slenco-BoldItalic.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/Slenco-BoldItalic.woff2")
      format("woff2"),
    /* Modern Browsers */ url("../assets/fonts/Slenco-BoldItalic.ttf")
      format("truetype"); /* Safari, Android, iOS */
  font-style: italic;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

/* Webfont: Slenco-Hairline */
@font-face {
  font-family: "SlencoHairline";
  src: url("../assets/fonts/Slenco-Hairline.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/Slenco-Hairline.woff2")
      format("woff2"),
    /* Modern Browsers */ url("../assets/fonts/Slenco-Hairline.ttf")
      format("truetype"); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Slenco-HairlineItalic */
@font-face {
  font-family: "SlencoIta";
  src: url("../assets/fonts/Slenco-HairlineItalic.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/Slenco-HairlineItalic.woff2")
      format("woff2"),
    /* Modern Browsers */ url("../assets/fonts/Slenco-HairlineItalic.ttf")
      format("truetype"); /* Safari, Android, iOS */
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Slenco-Italic */
@font-face {
  font-family: "SlencoIta";
  src: url("../assets/fonts/Slenco-Italic.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/Slenco-Italic.woff2")
      format("woff2"),
    /* Modern Browsers */ url("../assets/fonts/Slenco-Italic.ttf")
      format("truetype"); /* Safari, Android, iOS */
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Slenco-Light */
@font-face {
  font-family: "SlencoLight";
  src: url("../assets/fonts/Slenco-Light.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/Slenco-Light.woff2")
      format("woff2"),
    /* Modern Browsers */ url("../assets/fonts/Slenco-Light.ttf")
      format("truetype"); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Slenco-LightItalic */
@font-face {
  font-family: "SlencoLightIta";
  src: url("../assets/fonts/Slenco-LightItalic.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/Slenco-LightItalic.woff2")
      format("woff2"),
    /* Modern Browsers */ url("../assets/fonts/Slenco-LightItalic.ttf")
      format("truetype"); /* Safari, Android, iOS */
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Slenco-Regular */
@font-face {
  font-family: "Slenco";
  src: url("../assets/fonts/Slenco-Regular.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/Slenco-Regular.woff2")
      format("woff2"),
    /* Modern Browsers */ url("../assets/fonts/Slenco-Regular.ttf")
      format("truetype"); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

body {
  margin: 0;
  padding: 0;
}

.circle {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1vh;
  margin-bottom: 1vh;
  border: 15px solid black;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  display: table;
}

.circle_mobile {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1vh;
  margin-bottom: 1vh;
  border: 12.5px solid black;
  width: 370px;
  height: 370px;
  border-radius: 50%;
  display: table;
}

.circle_member {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1vh;
  margin-bottom: 1vh;
  border: 15px solid black;
  width: 350px;
  height: 350px;
  border-radius: 50%;
  display: table;
}

.circle_logo {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1vh;
  margin-bottom: 1vh;
  border: 15px solid;
  width: 550px;
  height: 550px;
  border-radius: 50%;
  display: table;
}

.navbar_animation {
  width: 100px;
  height: auto;
  display: table;
}

.circle_team {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1vh;
  margin-bottom: 1vh;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  border: 15px solid black;
  display: table;
}

.circle_projects {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1vh;
  margin-bottom: 1vh;
  border: 15px solid black;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  display: table;
  background-color: black;
}

.circle_black {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1vh;
  margin-bottom: 1vh;
  border: 15px solid black;
  width: 300px;
  height: 300px;
  border-radius: 100%;
  display: table;
  background-color: black;
}

.circle_contact {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1vh;
  margin-bottom: 1vh;
  width: 700px;
  height: 700px;
  border-radius: 50%;
  background: black;
}

.circle3 {
  margin: auto;
  display: table;
}

.text_menu {
  display: table-cell;
  vertical-align: middle;
  height: 276px;
  text-align: center;
  line-height: 0;
}

@media (max-width: 834px) {
  .container-fluid {
    padding-top: 0 !important;
  }
  .contactos {
    font-size: 30px !important;
  }
  .circle_member {
    width: 300px;
    height: 300px;
  }
  .cargo_membro {
    line-height: normal !important;
    text-align: center !important;
  }
  .posicao_membro {
    text-align: center !important;
  }
  .nome_membro {
    text-align: center !important;
  }
  h1 {
    font-size: 60px !important;
  }
  .podcast_title {
    width: 50% !important;
    margin: auto !important;
    margin-bottom: 5% !important;
  }
  .desktop_logo {
    display: none !important;
  }
  .mobile_logo {
    display: flex !important;
  }
  .mobile {
    display: flex !important;
  }
  .desktop {
    display: none !important;
  }
}

input[type="file"] {
  color: transparent;
}

@keyframes example1 {
  from {
    opacity: 0;
    transform: translateY(105%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes example2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes example3 {
  from {
    opacity: 0;
    transform: translateY(-105%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
