.footer-basic {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  color: black;
}

.footer-basic .social {
  margin: auto;
  text-align: center;
}

.footer-a {
  line-height: 4rem;
  text-align: center;
  color: white;
  opacity: 1;
}

.footer-img {
  display: inline-block;
  text-align: center;
}

@media (max-width: 576px) {
  .aocaboteatrologo {
   display: none;
  }
  .logoDgartes {
    display: none;
  }
}
